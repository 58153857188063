<!-- eslint-disable unused-imports/no-unused-vars -->
<script setup lang="ts">
import markdown from '@wcj/markdown-to-html'

const { t } = useI18n()

const host = await useGetHost('host')

const headers: any = { domain: host }

const history = ref<any>([])

const scrollAreaRef = ref<any>(null)

const inputRef = ref<any>(null)

const showhide = ref(false)
const loading = ref(false)
const userprompt = ref('')
const errorprompt = ref(false)

function scrollBottom() {
  if (scrollAreaRef.value.scrollHeight)
    scrollAreaRef.value.scrollTop = scrollAreaRef.value.scrollHeight
}

function textView(text: any) {
  if (text) {
    try {
      return markdown(text)
    }
    catch (e) {
      return `<pre>${text}</pre>`
    }
  }
  else {
    return ''
  }
}

async function onSubmit() {
  const prompt = userprompt.value.trim()

  userprompt.value = ''

  if (prompt) {
    errorprompt.value = false
    loading.value = true

    await history.value.push({
      role: 'user',
      parts: [{ text: prompt }],
    })

    scrollBottom()

    let aiResponse = ''

    try {
      const data: any = await $fetch('/api/chat', {
        method: 'post',
        headers,
        body: {
          prompt,
          history: history.value,
        },
      })

      aiResponse = data
    }
    catch (e) {
      aiResponse = t('ai_no_response')
    }

    await history.value.push({
      role: 'model',
      parts: [{ text: aiResponse }],
    })

    scrollBottom()

    loading.value = false
  }
  else {
    errorprompt.value = true
    loading.value = false

    if (inputRef.value)
      inputRef.value.focus()
  }
}

async function onChat() {
  showhide.value = !showhide.value

  if (inputRef.value)
    inputRef.value.focus()
}
</script>

<template>
  <!-- component -->
  <button
    title="Assistant"
    class="z-[3000] fixed bottom-2 right-2 inline-flex items-center justify-center text-sm font-medium disabled:pointer-events-none disabled:opacity-50 rounded-full w-12 h-12 bg-gray-800 hover:bg-gray-700 m-0 cursor-pointer bg-none p-0 normal-case leading-5 hover:text-gray-900"
    type="button" aria-haspopup="dialog" aria-expanded="false" data-state="closed"
    @click="onChat"
  >
    <span class="sr-only">Assistant</span>
    <svg
      v-if="!showhide"
      width="25" height="30" viewBox="0 0 24 24" fill="none" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
      class="text-white block border-gray-100 align-middle"
    >
      <path d="m3 21 1.9-5.7a8.5 8.5 0 1 1 3.8 3.8z" class="border-gray-100" />
    </svg>
    <svg v-else class="text-white block border-gray-100 align-middle" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18 17.94 6M18 18 6.06 6" />
    </svg>
  </button>

  <!-- Chat Container -->
  <div
    v-if="showhide"
    class="z-[3000] fixed bottom-[calc(3.5rem+1.5rem)] right-0 mr-0 md:mr-4 pt-4 bg-white rounded-3xl border border-[#e5e7eb] w-full md:w-[440px] h-[30rem] sm:h-[20rem] md:h-[35rem] shadow-xl"
  >
    <div class="relative h-full w-full">
      <!-- Chat Header -->
      <div class="h-[2rem] px-4 mb-4">
        <div class="flex items-center justify-between">
          <div class="text-xl font-medium ml-3">
            {{ t('ai_assistant') }}
          </div>
          <div v-if="history?.length">
            <UTooltip :text="t('ai_history_delete')">
              <UButton
                icon="i-heroicons-x-mark-20-solid"
                size="sm"
                color="gray"
                variant="soft"
                :padded="false"
                :ui="{ rounded: 'rounded-full' }"
                @click="history = []"
              />
            </UTooltip>
          </div>
        </div>
      </div>

      <!-- Chat Messages -->
      <div ref="scrollAreaRef" class="pl-6 pr-3 w-full h-[20rem] sm:h-[10rem] md:h-[25rem] overflow-y-auto">
        <div v-if="history?.length" class="space-y-4">
          <div
            v-for="(item, index) in history"
            :key="`NLFHyV${index}`"
            :class="item?.role === 'model' ? 'flex items-start' : 'flex items-end justify-end'"
          >
            <!-- Received Message -->
            <svg v-if="item?.role === 'model'" class="w-8 h-8 rounded-full" stroke="none" fill="black" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
            </svg>
            <div
              v-for="(ite, ind) in item?.parts"
              :key="`MylKfc${ind}`"
              :class="item?.role === 'model' ? 'ml-3 bg-gray-100 p-3 rounded-lg max-w-[90%] overflow-x-auto' : 'bg-blue-500 p-3 rounded-lg max-w-[90%] overflow-x-auto'"
            >
              <div :class="item?.role === 'model' ? 'text-base text-gray-800' : 'text-base text-white'" v-html="textView(ite?.text)" />
            </div>
            <!-- Sent Message -->
            <svg v-if="item?.role !== 'model'" class="w-8 h-8 rounded-full ml-3" stroke="none" fill="black" stroke-width="0" viewBox="0 0 16 16" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
            </svg>
          </div>
        </div>

        <div v-else class="h-full w-full flex items-center justify-center text-gray-600 text-base">
          {{ t('ai_welcome') }}
        </div>
      </div>

      <!-- Chat Input -->
      <div class="absolute bottom-3 w-full h-[5rem] px-6 mt-4 flex items-center text-base">
        <input
          ref="inputRef"
          v-model="userprompt"
          type="text"
          :placeholder="`${t('ai_type_message')}...`"
          class="flex-1 py-2 px-3 rounded-full bg-gray-100 focus:outline-none mr-3"
          :class="errorprompt ? ' border border-red-300' : ''"
          @keyup.enter.prevent="onSubmit"
        >

        <UButton
          :loading="loading"
          size="md"
          icon="i-heroicons-chat-bubble-oval-left"
          color="blue"
          :ui="{ rounded: 'rounded-full' }"
          @click="onSubmit"
        />
      </div>
    </div>
  </div>
</template>
